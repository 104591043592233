<template>
  <InitLayout :selectedLanguage="selectedLanguage.name">
    <b-row
      class="d-flex flex-row align-items-end justify-content-end w-100 px-5"
      no-gutters
    >
      <!-- <b-col cols="4" md="2">
        <FormSelect
          groupId="no-of-customers-group"
          id="no-of-customers-group-input"
          class="text-prime-gray rounded-2 "
          v-model="selectedLanguage"
          :whiteBG="false"
          :options="languageOptions"
          form="login-form"
          @input="changeLanguage"
        >
       </FormSelect>
      
      </b-col> -->
      <!-- <b-dropdown
        variant="white"
        toggle-class="d-flex flex-row align-items-center justify-content-center mx-0  my-lg-0 text-main-green border border-main-green text-main-green export-btn"
        :text="
          selectedLanguage ? selectedLanguage.name || 'English' : 'Language'
        "
        right
      >
        <b-dropdown-item
          v-for="language in languages"
          :key="language.code"
          @click="changeLanguage(language)"
        >
          <span :class="language.flag"></span>
          {{ language.name }}
        </b-dropdown-item>
      </b-dropdown> -->
    </b-row>

    <b-card
      no-body
      class="d-flex flex-column align-items-center justify-content-center bg-transparent border-0 rounded-0 px-1 px-lg-4 login-container-card"
    >
      <!-- login form -->
      <form
        id="login-form"
        class="d-flex flex-column align-items-start justify-content-center px-2 py-3 py-lg-0 px-lg-1 w-100"
      >
        <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100"
          no-gutters
        >
          <b-col cols="12" md="10">
            <b-row
              class="d-flex flex-row flex-nowrap align-items-center justify-content-center justify-self-center mb-3"
              no-gutters
            >
              <span
                class="font-primary font-weight-normal text-main-green text-center"
                >By signing up to the free {{ trialPeriodDays }} day trial
                period, enter the promo code <strong>FREEFIGA</strong> to extend
                the free trial period to {{ freeTrailEndDate }}</span
              >
            </b-row>
            <b-row
              class="d-flex flex-row flex-nowrap align-items-center justify-content-center justify-self-center mb-5"
              no-gutters
            >
              <span
                class="font-primary font-weight-normal text-main-green text-center"
                >Look out for the 'Promo Code' box in the Checkout section when
                purchasing your package.</span
              >
            </b-row>
            <h3 class="text-left text-main-green font-primary font-weight-bold">
              {{
                selectedLanguage.name === "Samoan" ? "Saini totonu" : "Sign In"
              }}
            </h3>
            <b-row
              class="d-flex flex-row flex-nowrap align-items-center justify-content-start mb-5"
              no-gutters
            >
              <span
                class="text-left text-gray-750 font-primary font-weight-normal"
              >
                {{
                  selectedLanguage.name === "Samoan"
                    ? "E lē oe o se sui auai?"
                    : "Not a member yet ?"
                }}
              </span>
              <b-link
                :href="registerUri"
                class="font-primary font-weight-normal text-main-green pl-2"
              >
                {{
                  selectedLanguage.name === "Samoan" ? "Saini luga" : "Sign Up"
                }}
              </b-link>
            </b-row>
          </b-col>
        </b-row>
        <!-- full row input (Username) -->
        <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100"
          no-gutters
        >
          <b-col cols="12" md="10">
            <FormInput
              groupId="user-name-group"
              id="user-name-group-input"
              class="text-prime-gray rounded-2"
              :state="emailError.status"
              :invalidFeedback="`${emailError.message}`"
              :label="selectedLanguage.name === 'Samoan' ? 'Imeli' : 'Email'"
              v-model="email"
              placeholder="user@example.com"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="text"
            ></FormInput>
            <!-- @keypress.native="isEmail(email)" -->
          </b-col>
        </b-row>
        <!-- full row input (Password) -->
        <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100"
          no-gutters
        >
          <b-col cols="12" md="10">
            <FormInputGroup
              groupId="password-group"
              id="password-group-input"
              v-model="password"
              :state="passwordError.status"
              :invalidFeedback="`${passwordError.message}`"
              :label="
                selectedLanguage.name === 'Samoan'
                  ? 'Upu fa\'alilolilo e lē iloa e se isi'
                  : 'Password'
              "
              :placeholder="
                selectedLanguage.name === 'Samoan'
                  ? 'itiiti ifo 6 mataitusi'
                  : 'Minimum 6 characters'
              "
              isRequired
              :whiteBG="false"
              :type="showPassword ? 'text' : 'password'"
              form="login-form"
              isAppend
            >
              <template v-slot:append>
                <b-button
                  class="px-3 px-md-4 rounded-0"
                  variant="transparent"
                  @click="showPassword = !showPassword"
                >
                  <b-icon
                    :icon="showPassword ? 'eye-slash' : 'eye'"
                    aria-label="Help"
                  ></b-icon>
                </b-button>
              </template>
            </FormInputGroup>
          </b-col>
        </b-row>
        <!-- error status -->
        <b-row
          v-if="loginError.status"
          class="d-flex flex-row align-items-center justify-content-center w-100 mb-3"
          no-gutters
        >
          <b-col cols="12" md="10">
            <span class="text-danger text-small text-center">{{
              this.loginError.message
            }}</span>
          </b-col>
        </b-row>
        <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100 mb-4"
          no-gutters
        >
          <!-- forgot password button/link -->
          <b-col cols="12" md="10">
            <b-link
              to="/forgot-password"
              class="font-primary font-weight-normal text-main-green"
            >
              {{
                selectedLanguage.name === "Samoan"
                  ? "Galo upu fa'ataga"
                  : "Forgot Password ?"
              }}
            </b-link>
          </b-col>
        </b-row>
        <!-- form action -->
        <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100 he"
          no-gutters
        >
          <!-- login button -->
          <b-col
            class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
            cols="12"
            md="10"
          >
            <FormButton
              isBlock
              isPill
              variant="main-green"
              class="font-weight-normal text-secondary mt-4 login-button"
              type="submit"
              :isLoading="isLoader"
              @click.native="loginFn"
              :class="{ 'btn-disabled': !isFormValid }"
              :disabled="!isFormValid"
              ><span class="text-white">
                {{
                  selectedLanguage.name === "Samoan"
                    ? "Saini totonu"
                    : " Sign in"
                }}
              </span></FormButton
            >
          </b-col>
        </b-row>
        <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100 mt-2"
          no-gutters
        >
          <!--          <b-col cols="12" md="10">-->
          <!--            <b-row-->
          <!--              class="d-flex flex-row align-items-center justify-content-start"-->
          <!--              no-gutters-->
          <!--            >-->
          <!--              <hr style="width: 45%; text-align: left; margin-left: 0" />-->
          <!--              <span class="text-gray-550 text-left">OR</span>-->
          <!--              <hr style="width: 45%; text-align: right; margin-right: 0" />-->
          <!--            </b-row>-->
          <!--          </b-col>-->
        </b-row>
        <!-- form action Google -->
        <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100"
          no-gutters
        >
          <!-- login button -->
          <b-col
            class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
            cols="12"
            md="10"
          >
            <FormButton
              variant="main-green"
              isBlock
              isPill
              class="font-weight-normal login-button"
            >
              <div
                class="d-flex flex-row justify-content-between align-items-center"
                style="width: 400px"
                @click="googleSignIn"
              >
                <img
                  src="@/assets/google-logo.png"
                  width="23px"
                  style="position: absolute"
                />
                <span class="mx-auto">
                  {{
                    selectedLanguage.name === "Samoan"
                      ? "Saini i le google"
                      : " Sign In with Google"
                  }}
                </span>
              </div>
            </FormButton>
          </b-col>
        </b-row>
      </form>
    </b-card>
  </InitLayout>
</template>

<script>
// services
//   import { LoginNew } from "../services/login.service";

import { mapActions, mapGetters } from "vuex";

// components
// @ is an alias to /src
import InitLayout from "@/layout/InitLayout";
import FormInput from "@/components/Form/FormInput";
import FormInputGroup from "@/components/Form/FormInputGroup";
import FormButton from "@/components/Form/FormButton";
import CheckEmail from "@/util/CheckEmail";
import { getGoogleUrl } from "@/util/auth/getGoogleUrl";
import getPromoCodeEndDate from "../util/getPromoCodeEndDate";
import { trial_period } from "../util/trialPeriod";
// import FormSelect from "@/components/Form/FormSelect";

export default {
  name: "Login",
  components: {
    InitLayout,
    FormInput,
    FormInputGroup,
    FormButton,
    // FormSelect
  },
  data() {
    return {
      selectedLanguage: { code: "", name: "English", flag: "" },
      freeTrailEndDate: null,
      trialPeriodDays: null,
      receivedParam: "",
      from: "/",
      isLoader: false,
      email: "",
      password: "",
      showPassword: false,
      errorEmail: null,
      errorPasswor: null,
      emailError: {
        status: null,
        message: "",
      },
      passwordError: {
        status: null,
        message: "",
      },
      loginError: {
        status: false,
        message: "",
      },
      registerUri: "",
      oauth_redirect: process.env.VUE_APP_GOOGLE_OAUTH_REDIRECT_LOGIN,
      domain: process.env.VUE_APP_DOMAIN,

      // languageOptions: [ { text: 'English', value: 'English', icon: 'fa-flag' },
      //   { text: 'Samoan', value: 'Samoan', icon: 'fa-flag' },],
      languages: [
        {
          name: "English",
          flag: "fi fi-nz",
          flagIconHtml: "<span class='fi fi-nz'></span>",
        },
        {
          name: "Samoan",
          flag: "fi fi-ws",
          flagIconHtml: "<span class='fi fi-ws'></span>",
        },
        // Add more languages as needed
      ],
    };
  },
  mounted() {
    this.freeTrailEndDate = getPromoCodeEndDate();
    this.trialPeriodDays = trial_period;
    const orginalUri = "/register";
    const next = this.$route.query.next;
    if (next) {
      this.registerUri = `${orginalUri}?next=${next}`;
    } else {
      this.registerUri = orginalUri;
    }
    this.checkOAuthCookies();

    // Get the query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const country = queryParams.get("country");
    // Save the received data in local storage
    localStorage.setItem("country", country);
    console.log("Received Country:", country);

    this.selectedLanguage.name = localStorage.getItem("selectedLanguage");
  },
  watch: {
    email(value) {
      this.loginError.status = false;
      if (value) {
        if (CheckEmail(value) == true) this.emailError.status = true;
        else {
          this.emailError.status = false;
          this.emailError.message = "Please enter a valid email address";
        }
      } else {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your email address";
      }
    },
    password(value) {
      this.loginError.status = false;
      if (value) {
        if (value.length < 6) {
          this.passwordError.status = false;
          this.passwordError.message =
            "A minimum length of 6 characters needed";
        } else {
          this.passwordError.status = true;
        }
      } else {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in your password";
      }
    },
  },
  computed: {
    isFormValid() {
      return (
        this.email !== "" && this.password !== "" && this.password.length >= 6
      );
    },
  },
  methods: {
    ...mapActions({
      localLogIn: "auth/localLogIn",
      workspaceLogin: "auth/workspaceLogin",
      setIsAuthenticated: "auth/setIsAuthenticated",
      setAccessToken: "auth/setAccessToken",
      clearValues: "businessRegistration/set_initial_state",
    }),

    ...mapGetters({
      getDefaultBusinessId: "auth/getDefaultBusinessId",
    }),
    async googleSignIn() {
      const from = "login";
      const googleUrl = getGoogleUrl(from, this.oauth_redirect);
      window.location.href = googleUrl;
    },
    async loginFn() {
      if (
        this.email &&
        this.password &&
        CheckEmail(this.email) &&
        this.passwordError.status
      ) {
        let payload = {
          // UserType: "Admin",
          email: this.email,
          password: this.password,
        };
        this.isLoader = true;

        this.localLogIn(payload)
          .then(async (res) => {
            console.log(res);
            if (this.$route.query.next) {
              window.location.href = this.$route.query.next;
              return;
            }
            if (this.getDefaultBusinessId()) {
              await this.workspaceLogin(this.getDefaultBusinessId());
              this.$router.push("/");
            } else {
              // TODO : Prompt user to select a business or create one.
              // localStorage.setItem("temp_pass", this.password);
              // localStorage.setItem("temp_email", this.email);
              this.$store.dispatch(
                "storeTempValues/setTempPassword",
                this.password
              );
              this.$store.dispatch("storeTempValues/setTempEmail", this.email);
              this.$router.push("/onboarding");
            }
          })
          .catch((error) => {
            // localStorage.setItem("temp_pass", this.password);
            // localStorage.setItem("temp_email", this.email);
            this.$store.dispatch(
              "storeTempValues/setTempPassword",
              this.password
            );
            this.$store.dispatch("storeTempValues/setTempEmail", this.email);
            console.log("error");
            console.log(error.response);
            if (
              error.response.data.msg === "No active package on this account!"
            ) {
              this.setIsAuthenticated(true);
              this.setAccessToken(error.response.data.access_token);
              this.$store.dispatch("general/clearTabSate");
              this.$router.push("/onboarding");
              this.$store.dispatch(
                "businessRegistration/set_name",
                error.response.data.data.businessname
              );
              this.$store.dispatch(
                "businessRegistration/set_country",
                error.response.data.data.businessCountry
              );
              this.$store.dispatch(
                "businessRegistration/Set_vat_label",
                error.response.data.data.vatLabel
              );
              this.$store.dispatch(
                "businessRegistration/set_currency",
                error.response.data.data.currency
              );

              return;
            }
            if (error.response.data.msg === "locked account.") {
              this.setIsAuthenticated(true);
              this.setAccessToken(error.response.data.access_token);
              // this.$router.push(`/packages?UnlockAccount=${true}`);
              this.$router.push({
                path: "/packages?UnlockAccount=${true}",
                query: {
                  lockedAccount: true,
                },
              });
              return;
            }

            if (
              error.response.data.msg === "Please verify your email address!"
            ) {
              this.setIsAuthenticated(true);
              this.setAccessToken(error.response.data.access_token);
              this.$router.push(`/register?isEmailVerified=${false}`);
              return;
            }

            this.loginError.message = error.response
              ? error.response.data.msg
              : error.message;
            this.loginError.status = true;
          })
          .finally(() => (this.isLoader = false));
      } else {
        this.validateForm();
      }
    },
    validateForm() {
      if (!this.email) {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your email address";
      }

      if (!this.password) {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in your password";
      }

      if (this.password < 6) {
        this.passwordError.status = false;
        this.passwordError.message = "A minimum length of 6 characters needed";
      }
    },
    async checkOAuthCookies() {
      this.isLoader = true;
      function removeSlashes(obj) {
        for (const key in obj) {
          if (obj.hasOwnProperty.call(key)) {
            if (typeof obj[key] === "string") {
              obj[key] = obj[key].replace(/\//g, ""); // Replace all slashes with an empty string
            } else if (typeof obj[key] === "object") {
              removeSlashes(obj[key]); // Recursively process nested objects
            }
          }
        }
      }
      const oauth_access_token = await this.getCookie("oauth_access_token");
      const oauth_member_of = await this.getCookie("oauth_member_of");
      const oauth_name = await this.getCookie("oauth_name");
      const oauth_email = await this.getCookie("oauth_email");
      const active_package = await this.getCookie("active_package");
      let active_package_obj;
      if (active_package) {
        active_package_obj = JSON.parse(decodeURIComponent(active_package));
        removeSlashes(active_package_obj);
      }
      if (oauth_access_token && oauth_email && oauth_name) {
        this.$store.dispatch("auth/setIsAuthenticated", true);
        this.$store.dispatch("auth/setAccessToken", oauth_access_token);
        // this.$store.dispatch("auth/setEmail", oauth_email);
        this.$store.dispatch("auth/setUserPackage", active_package_obj);
        this.$store.dispatch("auth/setUser", {
          member_of: JSON.parse(decodeURIComponent(oauth_member_of)),
          name: decodeURIComponent(oauth_name),
        });

        localStorage.setItem("auth-access-token", oauth_access_token);
        this.deleteCookie("oauth_access_token");
        this.deleteCookie("oauth_member_of");
        this.deleteCookie("oauth_name");
        this.deleteCookie("oauth_email");
        this.deleteCookie("oauth_refresh_token");
        this.deleteCookie("active_package");
        this.deleteCookie("oauth_default_workspace");
        if (this.getDefaultBusinessId()) {
          await this.workspaceLogin(this.getDefaultBusinessId());
          this.$router.push("/");
        }
      }
      this.isLoader = false;
    },

    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return parts.pop().split(";").shift();
      }
    },
    deleteCookie(name) {
      document.cookie =
        name +
        `=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.${this.domain}`;
    },
    changeLanguage(language) {
      this.selectedLanguage.name = language.name;
      localStorage.setItem("selectedLanguage", this.selectedLanguage.name);
    },
  },
};
</script>

<style scoped>
.login-container-card {
  width: 70%;
  min-width: 300px;
  min-height: 300px;
  max-width: 600px;
}

.login-container-card .login-button {
  opacity: 1;
  transition: opacity 200ms ease-in, box-shadow 200ms ease-in,
    color 200ms ease-in;
  border-radius: 3px;
}

.login-container-card .login-google-button {
  transition: opacity 200ms ease-in, box-shadow 200ms ease-in,
    color 200ms ease-in;
  border-radius: 1.8px;
}

.login-container-card .login-google-button:hover {
  background: #6e00cc;
  box-shadow: inset 0px 0px 10px #ffffff;
  color: #ffffff !important;
  border: none;
}

.btn-disabled {
  background: rgba(11, 155, 125, 0.2); /* light green */
  color: #fff;
  border: rgba(11, 155, 125, 0.2);
  cursor: not-allowed;
}

/*.g-logo {
  width: 23px;
  position: absolute;
  margin-right: 400px;
} */
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .login-container-card {
    min-height: fit-content;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .login-container-card {
    width: 90%;
  }
}
</style>
